export const BACKEND = (() => {
  switch (process.env.REACT_APP_STAGE) {
    case 'development':
      return 'http://localhost:5002';
    case 'qa':
      return 'https://qaebot.back3nd.xyz';
    default:
      return 'https://ebots.back3nd.xyz';
  }
})();

export const AIO = (() => {
  switch (process.env.REACT_APP_STAGE) {
    case 'development':
    case 'qa':
      return 'https://qaaio.back3nd.xyz';
    default:
      return 'https://aios.back3nd.xyz';
  }
})();

export const NOTIFICATIONS = `${AIO}/api/notifications`;

export const RESOURCES = 'https://resources.back3nd.xyz';

export const HOSTED = {
  localhost: {
    unit: 'xbot_dev',
    company: 'XBOT',
  },
  sgd: {
    unit: 'xbot_dev',
    company: 'XBOT',
  },
  pgda: {
    unit: 'pgd1',
    company: 'XBOT',
  },
  pgdb: {
    unit: 'pgd2',
    company: 'XBOT',
  },
  travinh: {
    unit: 'travinh',
    company: 'XBOT',
  },
  soctrang: {
    unit: 'soctrang',
    company: 'STEDU',
  },
  tpsoctrang: {
    unit: 'soctrang_tpsoctrang',
    company: 'XBOT',
  },
  mytu: {
    unit: 'soctrang_mytu',
    company: 'XBOT',
  },
  nganam: {
    unit: 'soctrang_nganam',
    company: 'XBOT',
  },
  culaodung: {
    unit: 'soctrang_culaodung',
    company: 'XBOT',
  },
  chauthanh: {
    unit: 'soctrang_chauthanh',
    company: 'ESOFT',
  },
  vinhchau: {
    unit: 'soctrang_vinhchau',
    company: 'ESOFT',
  },
  trande: {
    unit: 'soctrang_trande',
    company: 'ESOFT',
  },
  kesach: {
    unit: 'soctrang_kesach',
    company: 'STEDU',
  },
  myxuyen: {
    unit: 'soctrang_myxuyen',
    company: 'STEDU',
  },
  thanhtri: {
    unit: 'soctrang_thanhtri',
    company: 'STEDU',
  },
  longphu: {
    unit: 'soctrang_longphu',
    company: 'STEDU',
  },
  tct: {
    unit: 'tct_daknong',
    company: 'STEDU',
  },
  tieucan: {
    unit: 'travinh_tieucan',
    company: 'STEDU',
  },
  chauthanhtv: {
    unit: 'travinh_chauthanh',
    company: 'STEDU',
  },
  cauke: {
    unit: 'travinh_cauke',
    company: 'STEDU',
  },
  tracu: {
    unit: 'travinh_tracu',
    company: 'STEDU',
  },
  caungang: {
    unit: 'travinh_caungang',
    company: 'STEDU',
  },
  canglong: {
    unit: 'travinh_canglong',
    company: 'STEDU',
  },
  duyenhai: {
    unit: 'travinh_duyenhai',
    company: 'STEDU',
  },
  txduyenhai: {
    unit: 'travinh_txduyenhai',
    company: 'STEDU',
  },
  tptravinh: {
    unit: 'travinh_tptravinh',
    company: 'STEDU',
  },
  demo: {
    unit: 'demo',
    company: 'STEDU',
  },
  qa: {
    unit: 'xbot_dev',
    company: 'STEDU',
  },
};

export const UNITTYPES = {
  ctc: {
    name: 'Trường Chính trị',
    level: 'province',
  },
  sgd: {
    name: 'Sở Giáo dục và Đào tạo',
    level: 'province',
  },
  pgd: {
    name: 'Phòng Giáo dục và Đào tạo',
    level: 'district',
  },
};

export const PROPTYPES = {
  string: 'Chuỗi',
  memo: 'Đoạn văn bản',
  number: 'Số',
  date: 'Ngày/Tháng/Năm',
  bool: 'Có/Không',
  list: 'Danh sách',
};

export const LOGTYPES = {
  record: 'Số hoá',
  setting: 'Cấu hình',
  permission: 'Phân quyền',
  user: 'Người dùng',
};

export const DPROPS = [
  {
    code: 'fullName',
    name: 'Họ và tên',
    type: 'string',
    required: true,
    public: true,
    order: 1,
  },
  {
    code: 'birthDay',
    name: 'Ngày sinh',
    type: 'date',
    required: true,
    public: true,
    order: 2,
  },
  {
    code: 'gender',
    name: 'Giới tính',
    type: 'list',
    required: true,
    public: true,
    order: 3,
  },
  {
    code: 'birthPlace',
    name: 'Nơi sinh',
    type: 'list',
    public: true,
    order: 4,
  },
  {
    code: 'ethnic',
    name: 'Dân tộc',
    type: 'list',
    public: true,
    order: 5,
  },
  {
    code: 'school',
    name: 'Trường',
    type: 'list',
    public: true,
    order: 6,
  },
  {
    code: 'examBoard',
    name: 'Hội đồng thi',
    type: 'list',
    public: true,
    order: 7,
    removable: true,
  },
  {
    code: 'rate',
    name: 'Xếp loại',
    type: 'list',
    public: true,
    order: 8,
  },
  {
    code: 'grade',
    name: 'Học lực',
    type: 'list',
    order: 9,
    removable: true,
  },
  {
    code: 'conduct',
    name: 'Hạnh kiểm',
    type: 'list',
    order: 10,
    removable: true,
  },
  {
    code: 'docNo',
    name: 'Số bằng',
    type: 'string',
    public: true,
    order: 11,
  },
  {
    code: 'inputNo',
    name: 'Số vào sổ',
    type: 'string',
    public: false,
    order: 12,
  },
];

export const PROPSSTYLES = {
  fullName: {
    width: 200,
    sticky: 'left',
  },
  birthPlace: {
    width: 160,
  },
  school: {
    width: 250,
  },
  gender: {
    width: 100,
  },
  birthDay: {
    width: 110,
  },
  docNo: {
    width: 100,
  },
  ethnic: {
    width: 110,
  },
  inputNo: {
    width: 100,
  },
  rate: {
    width: 90,
  },
};

export const PERMISSIONS = {
  import: 'Nhập liệu',
  view: 'Tra cứu',
};
