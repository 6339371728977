import React, { useMemo } from 'react';

import { AutoComplete } from '@xbotvn/react-ui/components';
import {
  Typography,
  Box,
  TextField,
  Grid,
  FormControlLabel,
  Checkbox,
  Icon,
  Divider,
} from '@xbotvn/react-ui/core';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { useSelector } from 'react-redux';

import * as Styles from './styles';

function EsoftSearch({
  query,
  school,
  rangeYears,
  year,
  category,
  download,
  handleSearch,
  setUpdated,
  importFile,
  setCategory,
  setYear,
  setRangeYears,
  setSchool,
  setQuery,
}) {
  const { systemCategories, appCategories, isStaff } = useSelector(({ catalogs, user }) => ({
    systemCategories: catalogs?.system?.categories?.data ?? [],
    appCategories: catalogs?.app?.docTypes?.data ?? {},
    isStaff: (user?.unit?.staffs ?? []).includes(user.email) || user?.account?.xbot?.support,
  }));

  const categoryOptions = useMemo(() => {
    const result = systemCategories;
    Object.values(appCategories).forEach(({ category, name }) => {
      if (!result.find((item) => item.value === category)) {
        result.push({ value: category, text: name });
      }
    });

    return result;
  }, [systemCategories, appCategories]);

  return (
    <Styles.GroupSearch border="none" mt={3} ml={10} mr={10} mb={3}>
      <Typography variant="h5" align="center" style={{ fontWeight: 'bold' }}>
        Để tra cứu vui lòng nhập khoá thi và các thông tin sau
      </Typography>
      <Box m={3}>
        <Grid container spacing={1}>
          <Styles.GroupInput>
            <Grid item xs={6} style={{ marginRight: 15 }}>
              <TextField
                variant="outlined"
                label="Họ tên/Số bằng"
                required
                fullWidth
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                inputProps={{
                  onKeyPress: (event) => {
                    if (event.key === 'Enter') {
                      setUpdated();
                    }
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Trường"
                variant="outlined"
                value={school}
                onChange={(e) => setSchool(e.target.value)}
                inputProps={{
                  onKeyPress: (event) => {
                    if (event.key === 'Enter') {
                      setUpdated();
                    }
                  },
                }}
              />
            </Grid>
          </Styles.GroupInput>
          <Styles.GroupInput>
            {categoryOptions.length ? (
              <Grid item xs={6} style={{ marginRight: 15 }}>
                <AutoComplete
                  value={category}
                  options={categoryOptions.map(({ value }) => value)}
                  getOptionLabel={(opt) =>
                    categoryOptions.find(({ value }) => value === opt)?.text ?? ''
                  }
                  onChange={(e, value) => setCategory(value)}
                  inputProps={{
                    label: 'Văn bằng/Chứng chỉ',
                    required: true,
                    variant: 'outlined',
                  }}
                />
              </Grid>
            ) : null}
            <Grid item xs={1}>
              <TextField
                label="Khóa thi"
                variant="outlined"
                required
                fullWidth
                value={year}
                onChange={(e) => setYear(e.target.value)}
                type="number"
                inputProps={{
                  thousandSeparator: false,
                  onKeyPress: (event) => {
                    if (event.key === 'Enter') {
                      setUpdated();
                    }
                  },
                }}
              />
            </Grid>
            <Styles.RangeYear>
              {isStaff ? (
                <FormControlLabel
                  label="Các năm khác"
                  labelPlacement="end"
                  control={<Checkbox checked={rangeYears} onChange={setRangeYears} />}
                />
              ) : null}
            </Styles.RangeYear>
          </Styles.GroupInput>
        </Grid>
        <Box width={450} ml={52} mt={2}>
          <Box flexGrow={3} display="flex">
            <Styles.Actions color="secondary" startIcon={<Icon>download</Icon>} onClick={download}>
              Tải File Mẫu
            </Styles.Actions>
            <Dropzone multiple onDrop={(selected) => importFile(selected)}>
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <Styles.Actions color="secondary" startIcon={<Icon>receipt_long</Icon>}>
                    Tìm Theo Danh Sách
                  </Styles.Actions>
                </div>
              )}
            </Dropzone>
            <Styles.Actions color="primary" startIcon={<Icon>search</Icon>} onClick={handleSearch}>
              Tra Cứu
            </Styles.Actions>
          </Box>
        </Box>
      </Box>
      <Divider />
    </Styles.GroupSearch>
  );
}

EsoftSearch.defaultProps = {
  school: '',
  year: '',
  category: '',
};

EsoftSearch.propTypes = {
  query: PropTypes.string.isRequired,
  school: PropTypes.string,
  rangeYears: PropTypes.bool.isRequired,
  year: PropTypes.number,
  category: PropTypes.string,
  download: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  setUpdated: PropTypes.func.isRequired,
  importFile: PropTypes.func.isRequired,
  setCategory: PropTypes.func.isRequired,
  setYear: PropTypes.func.isRequired,
  setRangeYears: PropTypes.func.isRequired,
  setSchool: PropTypes.func.isRequired,
  setQuery: PropTypes.func.isRequired,
};

export default EsoftSearch;
