import { Notification } from '@xbotvn/react-ui/components';
import { takeLatest, takeEvery, put, all, select } from 'redux-saga/effects';

import { HOSTED } from '../../config';
import { auth, signedOut, graphQLCaller } from '../../libs/backend';
import { app } from '../../libs/firebase';
import { queryCities, queryCategories, queryCatalogs } from './catalogs';
import { USER, SIGN_OUT } from './constants';

export function* update(data = {}) {
  yield put({
    type: USER.update,
    ...data,
  });
}

export function* loadLiveSupport(user, unit) {
  const cities = (yield select())?.catalogs?.system?.cities?.data ?? {};

  const gender = user.gender === 'M' ? 'Thầy' : 'Cô';

  const province = cities?.unit?.province ?? {};
  const district = province?.districts?.[unit.district] ?? {};
  const ward = district?.wards?.[unit.ward] ?? {};

  if (window.fcWidget && window.fwcrm) {
    window.fwcrm.on('user:created', () => {
      window.fcWidget.setExternalId(user.uid);
      window.fcWidget.user.setFirstName(gender);
      window.fcWidget.user.setLastName(user.displayName);
      window.fcWidget.user.setEmail(user.email);
      window.fcWidget.user.setProperties({
        cf_unit_name: unit?.name,
        cf_unit_id: unit?.id,
        cf_province: province?.name || unit.id,
        cf_district: district?.name || unit.id,
        cf_ward: ward?.name || unit.id,
        cf_role: (unit.admins ?? [])?.includes(user?.email) ? 'Admin' : 'Staff',
      });
    });
  }
}

function* userSignedIn(user) {
  const domains = window.location.hostname.split('.');
  const activeUnit = HOSTED[domains?.[0]]?.unit ?? '';
  if (activeUnit) {
    const { email, uid } = user;
    yield* queryCatalogs(activeUnit);
    yield* queryCategories(activeUnit);
    if (email) {
      try {
        const { account, information, unit } = yield auth(email, uid, activeUnit);
        yield* queryCities();
        yield* update({
          uid,
          email,
          displayName: user?.displayName ?? email,
          photoURL: user?.photoURL ?? '',
          ...information,
          account,
          unit,
          authorized: true,
        });
        yield* loadLiveSupport(user, unit);
      } catch ({ message }) {
        yield* update({ email, uid, authorized: true });
        Notification.error(message);
      }
    } else {
      try {
        const { unit } = yield graphQLCaller(
          'units',
          `{
            unit(id: "${activeUnit}") {
              name
              address
            }
          }`
        );
        yield* update({
          authorized: true,
          unit: {
            id: activeUnit,
            ...unit,
          },
        });
      } catch ({ message }) {
        yield* update({ email, uid, authorized: true });
        Notification.error(message);
      }
    }
  } else {
    yield* update({ authorized: true });
    Notification.error('Unkown unit');
  }
}

function* userSignedOut() {
  yield app.auth().signOut();
  yield signedOut();
}

function* updateInformation({ displayName, phoneNumber, male, photoURL }) {
  const { email, uid } = (yield select())?.user ?? {};
  try {
    yield graphQLCaller(
      'users',
      `
      mutation updateUserInformation($uid: ID!, $information: UserInput!) {
        updateUserInformation(uid: $uid, information: $information)
      }
    `,
      {
        uid,
        information: {
          email,
          displayName,
          phoneNumber,
          male,
          photoURL,
        },
      }
    );
    yield* update({
      displayName,
      phoneNumber,
      male,
      photoURL,
    });
    Notification.success('Cập nhật thông tin tài khoản thành công.');
  } catch ({ message }) {
    yield* update();
    Notification.error(message);
  }
}

function* updateUnit({ admins, staffs, groups, other, onComplete }) {
  const { unit } = (yield select())?.user ?? {};
  if (!unit || !unit.id) yield* update();
  try {
    const convertedGroups = Object.entries(groups).map(([id, group]) => ({
      id,
      ...group,
    }));
    yield graphQLCaller(
      'units',
      `
      mutation updateUnitInformation($id: ID!, $information: UnitInput!) {
        updateUnitInformation(id: $id, information: $information)
      }
    `,
      {
        id: unit.id,
        information: {
          admins,
          staffs,
          groups: convertedGroups,
          ...other,
        },
      }
    );

    yield put({
      type: USER.unit,
      admins,
      staffs,
      groups,
    });
    Notification.success('Cập nhật thành công.', { action: onComplete });
  } catch ({ message }) {
    Notification.error(message);
    yield* update();
  }
}

export const handleUserUpdate = (user) => ({
  type: USER.handlers.update,
  ...user,
});

export const handleUserSignedIn = (user) => ({
  type: USER.handlers.login,
  ...user,
});

export const handleUserSignOut = () => ({
  type: SIGN_OUT,
});

export const handleUpdate = (data) => ({
  type: USER.update,
  ...data,
});

export const handleUpdateUnit = (staffs, admins, groups, others = {}, onComplete) => ({
  type: USER.handlers.unit,
  staffs,
  admins,
  groups,
  others,
  onComplete,
});

export default function* saga() {
  yield all([
    yield takeLatest(USER.handlers.login, userSignedIn),
    yield takeLatest(USER.handlers.update, updateInformation),
    yield takeEvery(SIGN_OUT, userSignedOut),
    yield takeLatest(USER.handlers.unit, updateUnit),
  ]);
}
